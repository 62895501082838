<template>
  <div class="influencers">
    <h4>Influencer Selection</h4>

    <v-tabs
      v-model="tab"
      @change="resetFilters()"
      grow
    >
      <v-tab>YouTube</v-tab>
      <v-tab>Instagram</v-tab>
    </v-tabs>

    <v-form ref="filterForm">
      <v-row class="mt-4">
        <!-- <v-col cols="6" class="d-flex align-center">
          <label>Platform</label>
          <v-select
            :items="platformType"
            item-text="label"
            item-value="value"
            label="Select Platform type"
            v-model="filter.type"
            @change="resetFilters()"
            solo
            hide-details
          >
          </v-select>
        </v-col> -->

        <v-col
          cols="6"
          class="d-flex align-center"
        >
          <label v-if="filter.type === 1">Channel Name</label>
          <label v-else>User Name</label>
          <v-text-field
            v-model="filter.channelName"
            placeholder="Enter channel/user name"
            solo
            hide-details
          ></v-text-field>
        </v-col>

        <v-col
          cols="6"
          class="d-flex align-center"
        >
          <label>Language</label>
          <v-select
            :items="languageList"
            label="Select Language"
            v-model="filter.language"
            multiple
            solo
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="6"
          class="d-flex align-center"
        >
          <label>Category</label>
          <v-select
            :items="tab === 0 ? categoryList : instaCategoryList"
            label="Select Category"
            v-model="filter.category"
            multiple
            solo
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="6"
          class="d-flex align-center"
        >
          <label>Gender</label>
          <v-select
            :items="genderList"
            label="Select Gender"
            v-model="filter.gender"
            multiple
            solo
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-row v-if="filter.type === 2">
        <v-col
          cols="6"
          class="d-flex align-center"
        >
          <label>Account Verify</label>
          <v-select
            :items="instaVerify"
            label="Select Account"
            item-text="label"
            item-value="value"
            v-model="filter.accountVerify"
            solo
            hide-details
          ></v-select>
        </v-col>
        <!-- <v-col
          cols="6"
          class="d-flex align-center"
        >
          <label>Account Type</label>
          <v-select
            :items="instaAccountType"
            label="Select Account Type"
            item-text="label"
            item-value="value"
            v-model="filter.accountType"
            solo
            hide-details
          ></v-select>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col
          cols="2"
          class="d-flex align-center"
        >
          <label v-if="filter.type === 1">Subscribers</label>
          <label v-else>Followers</label>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="filter.minimumSubscribers"
                placeholder="Minimum"
                solo
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="filter.maximumSubscribers"
                placeholder="Maximum"
                solo
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="5"
          class="d-flex align-center justify-start"
        >
          <label class="actionLabel">Quick Action:</label>
          <div>
            <v-chip
              @click="subscriberAction(1)"
              @click:close="subscriberAction(0)"
              :class="{ 'active': selectedSubscriberAction === 1 }"
              :close="selectedSubscriberAction === 1"
              label
              outlined
            >10k-50k</v-chip>
            <v-chip
              @click="subscriberAction(2)"
              @click:close="subscriberAction(0)"
              :class="{ 'active': selectedSubscriberAction === 2 }"
              :close="selectedSubscriberAction === 2"
              label
              outlined
            >50k-100k</v-chip>
            <v-chip
              @click="subscriberAction(3)"
              @click:close="subscriberAction(0)"
              :class="{ 'active': selectedSubscriberAction === 3 }"
              :close="selectedSubscriberAction === 3"
              label
              outlined
            >100k-200k</v-chip>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="filter.type === 1">
        <v-col
          cols="2"
          class="d-flex align-center"
        >
          <label>Avg. Views</label>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="filter.minimumAvgViews"
                placeholder="Minimum"
                solo
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="filter.maximumAvgViews"
                placeholder="Maximum"
                solo
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="5"
          class="d-flex align-center justify-start"
        >
          <label class="actionLabel">Quick Action:</label>
          <div>
            <v-chip
              @click="avgViewAction(1)"
              @click:close="avgViewAction(0)"
              :class="{ 'active': selectedAvgViesAction === 1 }"
              :close="selectedAvgViesAction === 1"
              label
              outlined
            >10k-50k</v-chip>
            <v-chip
              @click="avgViewAction(2)"
              @click:close="avgViewAction(0)"
              :class="{ 'active': selectedAvgViesAction === 2 }"
              :close="selectedAvgViesAction === 2"
              label
              outlined
            >50k-100k</v-chip>
            <v-chip
              @click="avgViewAction(3)"
              @click:close="avgViewAction(0)"
              :class="{ 'active': selectedAvgViesAction === 3 }"
              :close="selectedAvgViesAction === 3"
              label
              outlined
            >100k-200k</v-chip>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="filter.type === 1">
        <v-col
          cols="2"
          class="d-flex align-center"
        >
          <label>Engagement Rate</label>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="filter.minimumEngagementRate"
                placeholder="Minimum"
                solo
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="filter.maximumEngagementRate"
                placeholder="Maximum"
                solo
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="5"
          class="d-flex align-center justify-start"
        >
          <label class="actionLabel">Quick Action:</label>
          <div>
            <v-chip
              @click="engagementAction(1)"
              @click:close="engagementAction(0)"
              :class="{ 'active': selectedEngagementAction === 1 }"
              :close="selectedEngagementAction === 1"
              label
              outlined
            >0%-5%</v-chip>
            <v-chip
              @click="engagementAction(2)"
              @click:close="engagementAction(0)"
              :class="{ 'active': selectedEngagementAction === 2 }"
              :close="selectedEngagementAction === 2"
              label
              outlined
            >5%-10%</v-chip>
            <v-chip
              @click="engagementAction(3)"
              @click:close="engagementAction(0)"
              :class="{ 'active': selectedEngagementAction === 3 }"
              :close="selectedEngagementAction === 3"
              label
              outlined
            >10%-20%</v-chip>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="tab === 0">
        <v-col
          cols="2"
          class="d-flex align-center"
        >
          <label>Last Publish Date</label>
        </v-col>
        <v-col
          cols="9"
          class="d-flex align-center justify-start"
        >
          <v-chip
            @click:close="senddate(0)"
            @click="senddate(1)"
            :class="{ 'active': selectedDateAction === 1 }"
            :close="selectedDateAction === 1"
            label
            outlined
          >One Week</v-chip>
          <v-chip
            @click="senddate(2)"
            @click:close="senddate(0)"
            :class="{ 'active': selectedDateAction === 2 }"
            :close="selectedDateAction === 2"
            label
            outlined
          >One Month</v-chip>
          <v-chip
            @click="senddate(3)"
            @click:close="senddate(0)"
            :class="{ 'active': selectedDateAction === 3 }"
            :close="selectedDateAction === 3"
            label
            outlined
          >Three Months</v-chip>
          <v-chip
            @click="senddate(4)"
            @click:close="senddate(0)"
            :class="{ 'active': selectedDateAction === 4 }"
            :close="selectedDateAction === 4"
            label
            outlined
          >One Year</v-chip>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="2"
          class="d-flex align-center"
        >
        </v-col>
        <v-col
          cols="9"
          class="d-flex justify-space-between"
        >
          <v-btn
            depressed
            dark
            large
            color="primary"
            style="width: 49%;"
            :disabled="loading"
            :loading="loading"
            @click="applyFilter()"
          >Apply Filter</v-btn>
          <v-btn
            depressed
            dark
            large
            color="primary"
            style="width: 49%;"
            :disabled="loading"
            @click="resetFilters()"
          >Reset All</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-progress-linear
      indeterminate
      class="mt-4 mb-4"
      color="primary"
      v-if="loadingTable"
    ></v-progress-linear>

    <div v-if="this.filter.type === 1">

      <v-simple-table
        class="mt-4 mb-4"
        v-if="influencerList.length > 0"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Channel</th>
              <!-- <th class="text-center">Channel LInk</th> -->
              <th class="text-center">Language</th>
              <!-- <th class="text-center">Category</th> -->
              <!-- <th class="text-center" width="200">Category</th> -->
              <th class="text-center">YouTube Category</th>
              <th class="text-center">Gender</th>
              <th class="text-center">Subscribers</th>
              <th class="text-center">Total View</th>
              <th class="text-center">Total Video</th>
              <th class="text-center">Avg View</th>
              <th class="text-center">Engagement Rate</th>
              <th class="text-center">Last Publish Date</th>
              <th class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in influencerList"
              :key="item.channel_id"
            >
              <td class="channelMeta">
                <a
                  :href="item.channel_link"
                  target="_blank"
                  class="d-flex align-center"
                >
                  <div>
                    <v-img
                      v-if="item.avatar"
                      :src="item.avatar"
                    ></v-img>
                    <!-- <div
                      v-else
                      class="team-image"
                    > -->
                    <!-- {{ item.channel_name ? item.channel_name.charAt(0) : 'NA' }} -->
                    <v-img
                      v-else
                      :src="`https://ui-avatars.com/api/?name=${item.channel_name}&background=F54153&color=FFF`"
                    ></v-img>
                    <!-- </div> -->
                  </div>
                  <p>
                    <strong>{{ item.channel_name }}</strong> <br>
                    <v-chip
                      v-if="item.category"
                      x-small
                      color="teal"
                      text-color="white"
                    >
                      {{ item.category }}
                    </v-chip>
                  </p>
                </a>
              </td>
              <td class="text-center">{{ item.language ? item.language : '-' }}</td>
              <td class="text-center">{{ item.yt_category ? catName(item.yt_category) : '-'}}</td>
              <td class="text-center">{{ item.gender ? item.gender : '-' }}</td>
              <td class="text-center">{{ item.subscribers | formatNumber  }}</td>
              <td class="text-center">{{ item.total_view | formatNumber }}</td>
              <td class="text-center">{{ item.total_video | formatNumber  }}</td>
              <td class="text-center">{{ item.latest_avg_view | formatNumber }}</td>
              <td class="text-center">{{ item.eng_rate ? item.eng_rate + '%' : '-'}}</td>
              <td>{{ item.last_video_date | dateFormat }}</td>
              <td class="text-center">
                <div class="d-flex align-center justify-center">
                  <img
                    src="@/assets/images/add.svg"
                    width="24"
                    class="mr-2"
                    style="cursor:pointer"
                    @click="openDialogCampaign(item.id)"
                    alt="Assign Campaign"
                  >

                  <v-icon
                    size="28"
                    color="secondary"
                    @click="addRemoveFavourite(item)"
                    alt="Add to Favourite"
                  >
                    {{item.is_fav ? 'mdi-heart' : 'mdi-heart-outline'}}
                  </v-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div
        class="text-center ma-8"
        v-if="!loadingTable && influencerList.length <= 0"
      >
        <img
          src="@/assets/images/no-campaign.png"
          width="150"
        />
        <h3>No Influencers Found</h3>
      </div>
    </div>

    <div v-else>

      <v-simple-table
        class="mt-4 mb-4"
        fixed-header
        v-if="influencerList.length > 0"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Channel</th>
              <!-- <th class="text-center">Instagram Name</th>
              <th class="text-center">Insta LInk</th>
              <th class="text-center">Category</th> -->
              <th class="text-center">Language</th>
              <th class="text-center">Gender</th>
              <th class="text-center">Followers</th>
              <th class="text-center">Total Media</th>
              <!-- <th class="text-center">Account Verify</th> -->
              <!-- <th class="text-center">Account Type</th> -->
              <th class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in influencerList"
              :key="item.insta_username"
            >
              <td class="channelMeta">
                <a
                  :href="item.insta_link"
                  target="_blank"
                  class="d-flex align-center"
                >
                  <div>
                    <v-badge
                      v-if="item.is_verify"
                      color="success"
                      icon="mdi-check"
                      avatar
                      bordered
                      overlap
                    >
                      <v-avatar size="45">
                        <v-img :src="`https://ui-avatars.com/api/?name=${item.insta_name}&background=F54153&color=FFF`"></v-img>
                      </v-avatar>
                    </v-badge>
                    <v-img
                      v-else
                      :src="`https://ui-avatars.com/api/?name=${item.insta_name}&background=F54153&color=FFF`"
                    ></v-img>
                  </div>
                  <p>
                    <strong>{{ item.insta_name }}</strong>
                    <!-- <v-icon v-if="item.is_verify" color="success" small left>mdi-check-circle-outline</v-icon> -->
                    <br>
                    <v-chip
                      v-if="item.category"
                      x-small
                      color="teal"
                      text-color="white"
                    >
                      {{ item.category }}
                    </v-chip>
                  </p>
                </a>
              </td>
              <!-- <td class="text-center">
                <a
                  :href="item.insta_link"
                  target="_blank"
                >
                  <v-img
                    :src="`https://ui-avatars.com/api/?name=${item.insta_name}&background=F54153&color=FFF`"
                    width="60"
                    :aspect-ratio="16/16"
                  ></v-img>
                </a>
              </td>
              <td class="text-center">{{ item.insta_name }}</td>
              <td class="text-center"><a
                  v-bind:href="item.insta_link"
                  target="_blank"
                  class="nav-link"
                  style="color:blue;"
                > Channel Link
                </a></td>
              <td class="text-center">{{ item.category }}</td> -->
              <td class="text-center">{{ item.language ? item.language : '-' }}</td>
              <td class="text-center">{{ item.gender ? item.gender : '-' }}</td>
              <td class="text-center">{{ item.followers | formatNumber  }}</td>
              <td class="text-center">{{ item.total_media | formatNumber  }}</td>
              <!-- <td class="text-center">{{ item.is_verify ? verify(item.is_verify) : '-' }}</td> -->
              <!-- <td class="text-center">{{ item.is_business | accountType }}</td> -->
              <td class="text-center">
                <div class="d-flex align-center justify-center">
                  <img
                    src="@/assets/images/add.svg"
                    width="24"
                    class="mr-2"
                    style="cursor:pointer"
                    @click="openDialogCampaign(item.id)"
                  >

                  <v-icon
                    size="28"
                    color="secondary"
                    @click="addRemoveFavourite(item)"
                    alt="Add to Favourite"
                  >
                    {{item.is_fav ? 'mdi-heart' : 'mdi-heart-outline'}}
                  </v-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div
        class="text-center ma-8"
        v-if="!loadingTable && influencerList.length <= 0"
      >
        <img
          src="@/assets/images/no-campaign.png"
          width="150"
        />
        <h3>No Influencers Found</h3>
      </div>
    </div>

    <v-pagination
      v-model="filter.page"
      :length="this.totalPages"
      @input="getInfluencers()"
      :total-visible="10"
      v-if="!loadingTable && this.totalPages > 1"
    ></v-pagination>

    <v-dialog
      v-model="dialogCampaign"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>

        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>
            <!-- Your {{this.tab + 1 | platform}} -->
            <v-icon
              v-if="this.tab === 0"
              class="mr-2"
              color="#ff0101"
              x-large
            >mdi-youtube</v-icon>
            <v-icon
              v-else
              class="mr-2"
              color="#ff0101"
              x-large
            >mdi-instagram</v-icon>
            Progress Campaigns
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="dialogCampaign = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :loading="loadingCam"
          loading-text="Loading... Please wait"
          show-select
          :disable-sort="true"
          @item-selected="selectData"
          v-model="selected"
          :items="indexedItems"
          mobile-breakpoint="0"
          class="ma-4"
          hide-default-footer
        >
          <!-- :items-per-page="20" -->
          <!-- :footer-props="{'items-per-page-options': [20,40,60,80,100]}" -->
          <!-- @toggle-select-all="alldata" -->
          <!-- @toggle-select-all="alldata" -->
          <!-- commented to remove select all row option -->
          <template v-slot:[`header.data-table-select`]>Selected</template> <!--  remove select all row option to title -->
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.status === 0"
              color="warning"
              small
            > Pending
            </v-chip>
            <v-chip
              v-if="item.status === 1"
              color="success"
              small
            > Approved
            </v-chip>
            <v-chip
              v-if="item.status === 2"
              color="error"
              small
            > Rejected
            </v-chip>
          </template>
          <template v-slot:[`item.campaign_type`]="{ item }">
            {{item.campaign_type | campaignType}}
          </template>
          <template v-slot:[`item.content_type`]="{ item }">
            <span v-if="item.promo_platform === 1">{{item.content_type | youtube}}</span>
            <span v-else>{{item.content_type | instagram}}</span>
          </template>
          <template v-slot:[`item.category`]="{ item }">
            <span
              v-for="category in item.category"
              :key="category"
            >{{ category }}, </span>
          </template>
          <template v-slot:[`item.language`]="{ item }">
            <span
              v-for="language in item.language"
              :key="language"
            >{{ language }}, </span>
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            <span
              v-for="gender in item.gender"
              :key="gender"
            >{{ gender }}, </span>
          </template>
          <template v-slot:[`item.add_time`]="{ item }">
            {{item.add_time | dateFormat }}
          </template>
          <!-- <template v-slot:[`item.campaign_status`]="{ item }">
            <v-chip
              v-if="item.campaign_status === 0"
              color="warning"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-alert-circle-outline</v-icon>Draft
            </v-chip>
            <v-chip
              v-if="item.campaign_status === 1"
              color="success"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-progress-clock</v-icon>Progress
            </v-chip>
            <v-chip
              v-if="item.campaign_status === 2"
              color="error"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-close-circle-outline</v-icon>Cancelled
            </v-chip>
            <v-chip
              v-if="item.campaign_status === 4"
              color="primary"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-check-circle-outline</v-icon>Completed
            </v-chip>
          </template> -->
          <template v-slot:[`item.budget`]="{ item }">
            {{item.budget | formatNumber }}
          </template>
        </v-data-table>
        <div
          class="text-center ma-8"
          v-if="campList.length <= 0 && !loadingCam"
        >
          <img
            src="@/assets/images/no-campaign.png"
            width="150"
          />
          <h3>No Campaigns Found</h3>
          <v-btn
            depressed
            dark
            large
            color="primary"
            to="/campaign/create"
            style="width: 350px;"
          >Create your First Campaign</v-btn>
        </div>
        <div
          class="d-flex align-center justify-center pb-2"
          v-if="campList.length > 0"
        >
          <v-btn
            depressed
            dark
            large
            @click="submitSelectedCampaign"
            color="primary"
            :loading="loadingCam"
            style="width: 252px;"
          >Assign Campaigns</v-btn>
        </div>
        <v-pagination
          class="mt-2"
          v-model="assignCamp.page"
          :length="this.totalCampPages"
          @input="paginationCamp()"
          :total-visible="10"
          v-if="!loadingCam && totalCampPages > 1"
        ></v-pagination>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import constantData from '@/utils/constant'
import { getList, submitSelectCampaign, influAssignCampList, favAdd, favDelete } from '@/api/influencer'

export default {
  data () {
    return {
      tab: 0,
      user: JSON.parse(window.localStorage.getItem('user')) || {},
      selectedSubscriberAction: 0,
      selectedAvgViesAction: 0,
      selectedEngagementAction: 0,
      selectedDateAction: 0,
      dialogCampaign: false,
      loadingTable: false,
      loadingCam: false,
      selected: [],
      multipleSelection: [],
      loading: false,
      infId: null,
      campList: [],
      assignCamp: {
        type: null,
        page: 1,
        size: 10,
        influencerId: null
      },
      headers: [
        { text: 'Campaign Name', value: 'campaign_name', align: 'center' },
        { text: 'Campaign Type', value: 'campaign_type', align: 'center' },
        { text: 'Content Type', value: 'content_type', align: 'center' },
        { text: 'Category', value: 'category', align: 'center' },
        { text: 'Language', value: 'language', align: 'center' },
        { text: 'Gender', value: 'gender', align: 'center' },
        { text: 'Budget', value: 'budget', align: 'center' },
        { text: 'Admin Approval', value: 'status', align: 'center' },
        // { text: 'Status', value: 'campaign_status', align: 'center' },
        { text: 'Add Time', value: 'add_time', align: 'center' }
      ],
      languageList: constantData.language,
      categoryList: constantData.category,
      instaCategoryList: constantData.instaCategoryList,
      ytCategory: constantData.ytCategory,
      platformType: constantData.platformList,
      genderList: constantData.genderList,
      // instaAccountType: constantData.instaAccountTypeList,
      instaVerify: constantData.instaVerifyList,
      totalPages: 0,
      totalCampPages: 0,
      filter: {
        page: 1,
        size: 10,
        type: 1,
        language: [],
        category: [],
        gender: []
      },
      items: [''],
      influencerList: []
    }
  },
  computed: {
    indexedItems () {
      return this.campList.map((item, index) => ({
        id: index,
        ...item
      }))
    }
  },
  mounted () {
    this.getInfluencers()
  },
  methods: {
    subscriberAction (value) {
      this.selectedSubscriberAction = value
      switch (value) {
        case 1:
          this.filter.minimumSubscribers = 10000
          this.filter.maximumSubscribers = 50000
          break

        case 2:
          this.filter.minimumSubscribers = 50000
          this.filter.maximumSubscribers = 100000
          break

        case 3:
          this.filter.minimumSubscribers = 100000
          this.filter.maximumSubscribers = 200000
          break

        default:
          this.filter.minimumSubscribers = null
          this.filter.maximumSubscribers = null
          break
      }
    },
    senddate (value) {
      this.selectedDateAction = value
      switch (value) {
        case 1:
          this.filter.dateType = 1
          break

        case 2:
          this.filter.dateType = 2
          break

        case 3:
          this.filter.dateType = 3
          break

        case 4:
          this.filter.dateType = 4
          break

        default:
          this.filter.dateType = null
          break
      }
    },
    avgViewAction (value) {
      this.selectedAvgViesAction = value
      switch (value) {
        case 1:
          this.filter.minimumAvgViews = 10000
          this.filter.maximumAvgViews = 50000
          break

        case 2:
          this.filter.minimumAvgViews = 50000
          this.filter.maximumAvgViews = 100000
          break

        case 3:
          this.filter.minimumAvgViews = 100000
          this.filter.maximumAvgViews = 200000
          break

        default:
          this.filter.minimumAvgViews = null
          this.filter.maximumAvgViews = null
          break
      }
    },
    engagementAction (value) {
      this.selectedEngagementAction = value
      switch (value) {
        case 1:
          this.filter.minimumEngagementRate = 0
          this.filter.maximumEngagementRate = 5
          break

        case 2:
          this.filter.minimumEngagementRate = 5
          this.filter.maximumEngagementRate = 10
          break

        case 3:
          this.filter.minimumEngagementRate = 10
          this.filter.maximumEngagementRate = 100
          break

        default:
          this.filter.minimumEngagementRate = null
          this.filter.maximumEngagementRate = null
          break
      }
    },
    catName (dataValue) {
      const newvalue = dataValue.split(',')
      var newcat = []
      for (let i = 0; i < newvalue.length; i++) {
        var cat = this.ytCategory.filter(function (obj) {
          return obj.value === newvalue[i]
        }).map(function (obj) {
          return obj.label
        })
        newcat = newcat.concat(cat)
      }
      if (!newcat.length) {
        return '-'
      } else {
        return newcat.toString()
      }
    },
    // alldata (selected) {
    //   if (selected.value === true) {
    //     this.multipleSelection = selected.items.map(item => { return item.campaign_id })
    //   } else {
    //     this.multipleSelection = []
    //   }
    // },
    selectData (selected) {
      const id = selected.item.campaign_id
      if (selected.value === true) {
        this.selected.push(selected.item)
        this.multipleSelection.push(id)
        this.multipleSelection = [...new Set(this.multipleSelection)]
      } else {
        if (selected.item.status === 2 || selected.item.status === 1) {
          this.$snackbar({
            message: `${selected.item.status === 2 ? 'Already Rejected' : 'Already Approved'}`,
            color: 'warning',
            timeout: 2000
          })
          this.selected.push(selected.item)
        } else {
          this.selected.splice(this.selected.findIndex(a => a.campaign_id === id), 1)
          const index = this.multipleSelection.indexOf(id)
          this.multipleSelection.splice(index, 1)
        }
      }
      this.selected = [...new Set(this.selected.map(item => item))]
    },
    async getInfluencers () {
      // this.influencerList = []
      window.scrollTo({ top: 600, behavior: 'smooth' })
      this.loading = true
      this.loadingTable = true
      const filterData = {
        language: this.filter.language.join(),
        gender: this.filter.gender.join(),
        category: this.filter.category.join(),
        type: this.filter.type,
        channelName: this.filter.channelName,
        accountVerify: this.filter.accountVerify,
        // accountType: this.filter.accountType,
        minimumSubscribers: this.filter.minimumSubscribers,
        maximumSubscribers: this.filter.maximumSubscribers,
        minimumAvgViews: this.filter.minimumAvgViews,
        maximumAvgViews: this.filter.maximumAvgViews,
        minimumEngagementRate: this.filter.minimumEngagementRate,
        maximumEngagementRate: this.filter.maximumEngagementRate,
        dateType: this.filter.dateType,
        page: this.filter.page,
        size: this.filter.size,
        clientid: this.user.id || null
      }
      const data = await getList(filterData)
      if (data.success) {
        this.influencerList = data.list
        this.totalPages = Math.ceil(data.total_count / 10)
        window.scrollTo({ top: 600, behavior: 'smooth' })
      } else {
        this.$snackbar({
          message: data.message,
          color: 'error',
          timeout: 5000
        })
        this.favList = []
      }
      this.loading = false
      this.loadingTable = false
    },
    applyFilter () {
      this.totalPages = 0
      this.filter.page = 1
      this.filter.size = 10
      this.getInfluencers()
    },
    resetFilters () {
      // this.$refs.filterForm.reset()
      this.influencerList = []
      this.totalPages = 0
      this.filter = {
        page: 1,
        size: 10,
        type: this.tab === 0 ? 1 : 2,
        language: [],
        category: [],
        gender: []
      }
      this.selectedSubscriberAction = 0
      this.selectedAvgViesAction = 0
      this.selectedEngagementAction = 0
      this.selectedDateAction = 0
      this.getInfluencers()
    },
    async openDialogCampaign (id) {
      if (!this.user || !this.user.id) {
        this.$router.push(`/user/login?redirect=${this.$route.path}`)
        return
      }
      this.infId = id
      this.campList = []
      this.selected = []
      this.multipleSelection = []
      this.loadingCam = true
      this.dialogCampaign = true
      this.assignCamp.page = 1
      this.assignCamp.type = this.filter.type
      this.assignCamp.influencerId = id
      const data = await influAssignCampList(this.assignCamp)
      this.loadingCam = false
      if (data.success) {
        this.campList = data.campaigns.map(item => {
          item.category = item.category.split(',') || []
          item.language = item.language.split(',') || []
          item.gender = item.gender.split(',') || []
          return item
        })
        this.totalCampPages = Math.ceil(data.total_count / 10)
        this.selected = this.campList.filter((item, index) => item.assignCamp === '1')
        this.multipleSelection = this.selected.map(item => { return item.campaign_id })
      } else {
        this.$snackbar({
          message: data.message
        })
      }
    },
    async paginationCamp () {
      this.loadingCam = true
      const data = await influAssignCampList(this.assignCamp)
      this.loadingCam = false
      if (data.success) {
        this.campList = data.campaigns.map(item => {
          item.category = item.category.split(',') || []
          item.language = item.language.split(',') || []
          item.gender = item.gender.split(',') || []
          return item
        })
        this.totalCampPages = Math.ceil(data.total_count / 10)
        this.selected = this.campList.filter((item, index) => item.assignCamp === '1')
        this.multipleSelection = this.selected.map(item => { return item.campaign_id })
      } else {
        this.$snackbar({
          message: data.message
        })
      }
    },
    async submitSelectedCampaign () {
      // if (!this.multipleSelection.length) {
      //   this.$snackbar({
      //     color: 'error',
      //     timeout: 3000,
      //     message: 'Please select campaign first'
      //   })
      //   return
      // }
      const item = {
        assignData: this.multipleSelection,
        infId: this.infId,
        type: this.filter.type,
        page: this.assignCamp.page,
        size: this.assignCamp.size
      }
      this.loadingCam = true
      const data = await submitSelectCampaign(item)
      if (data.success) {
        this.$snackbar({
          message: data.message
        })
        this.dialogCampaign = false
      } else {
        this.$snackbar({
          message: 'Something went wrong',
          color: 'error',
          timeout: 5000
        })
      }
      this.loadingCam = false
    },
    async addRemoveFavourite (item) {
      if (!this.user || !this.user.id) {
        this.$router.push(`/user/login?redirect=${this.$route.path}`)
        return
      }
      item.is_fav = !item.is_fav

      if (item.is_fav) {
        const data = await favAdd({ influencerId: item.id, type: this.filter.type })
        if (data.success) {
          this.$snackbar({
            message: data.message
          })
        }
      } else {
        const data = await favDelete({ id: item.fav_inf_id })
        if (data.success) {
          this.$snackbar({
            message: data.message,
            color: 'error',
            timeout: 5000
          })
        }
      }
      this.getInfluencers()
    }
  }
}
</script>

<style scoped>
  h4 {
    text-transform: uppercase;
    margin: 0;
  }
  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: #363a54;
    display: inline-block;
    width: 185px;
  }
  label.actionLabel {
    width: 120px;
  }
  .v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px;
  }
  .v-text-field.v-text-field--enclosed.v-text-field--solo {
    margin: 0;
  }
  .v-chip.v-size--default {
    height: 40px;
    border: 0.8px solid rgba(51, 51, 51, 0.15);
    filter: drop-shadow(-2px 3px 10px rgba(245, 65, 83, 0.26));
    border-radius: 11px !important;
    margin: 0 7px 0 0;
    padding: 0 8px;
    /* font-size: 12px; */
    cursor: pointer !important;
  }
  .v-chip.v-size--default.active {
    background: #f54153 !important;
    border: 0.8px solid #f54153;
    color: #ffffff;
  }

  .v-data-table >>> .v-data-table__wrapper > table {
    border-spacing: 0 12px !important;
  }
  .theme--light.v-data-table >>> .v-data-table__wrapper table th {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    border: none !important;
    padding: 5px 6px;
  }
  .v-data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    color: #333333;
    border: none !important;
    padding: 5px 6px;
  }
  .v-data-table >>> .v-data-table__wrapper > table > tbody > tr {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
    border-radius: 13px;
  }
  .v-image {
    border-radius: 50%;
    height: 45px;
    width: 45px;
  }
  h3 {
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    color: #6b6b6b;
    margin: 20px 0;
  }
</style>
